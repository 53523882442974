import React, { FC } from 'react';

import { Modal } from 'antd';
import { ArrowLeft } from 'react-feather';

import SearchBox from '../../search-box/search-box.module';
import styles from './search-modal.module.scss';

interface Props {
  visibility: boolean;
  onCancel: () => void;
}

const MobileSearchModal: FC<Props> = ({ visibility, onCancel }) => {
  return (
    <Modal
      centered
      destroyOnClose
      title=""
      closable={false}
      maskClosable={false}
      visible={visibility}
      className={'modal--full'}
      onCancel={onCancel}
      bodyStyle={{
        padding: 0,
        position: 'relative',
      }}
      footer={null}
    >
      <div className={styles.BackArrow} onClick={onCancel}>
        <ArrowLeft />
      </div>
      <SearchBox
        showOverlay={() => {}}
        autoFocus={true}
        onItemSelect={onCancel}
      />
    </Modal>
  );
};

export default MobileSearchModal;

import React, { FC, useMemo } from 'react';
import { getAuth } from 'firebase/auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ChevronRight } from 'react-feather';

import { Button, Design, Wording } from '@components';
import { ActiveTab } from '@helpers';

import classes from './user-controls.module.scss';

interface Props {
  onAuthModal: (tab: ActiveTab) => void;
}

const MobileUserControl: FC<Props> = ({ onAuthModal }) => {
  const router = useRouter();
  const { currentUser } = getAuth();
  const isAnonymous = currentUser?.isAnonymous ?? true;

  // showing the sign-in modal
  const onShowSignIn = () => onAuthModal(ActiveTab.LOGIN);

  const renderText = useMemo(() => {
    if (currentUser?.displayName)
      return {
        title: currentUser?.displayName ?? '',
        link: 'Your Account',
      };
    return {
      title: currentUser?.phoneNumber ?? '',
      link: 'Complete Account Info',
    };
  }, [currentUser]);

  if (isAnonymous)
    return (
      <div className={classes.AnonymousDropdown}>
        <Wording fontSize="14px" fontWeight="500" color="surface">
          Hello! Welcome to 1Sell
        </Wording>

        <Design display="flex" alignItems="center" justifyContent="center">
          <Button
            className={classes.ActionButtons}
            type="primary"
            fullWidth
            onClick={onShowSignIn}
          >
            Login/Register
          </Button>
        </Design>
      </div>
    );

  return (
    <div
      className={classes.AuthorizedDropdown}
      onClick={() => router.push('/profile')}
    >
      <header>
        <img src="/icons/user-profile-icon.svg" alt="User Icon" />
        <div>
          <p>{renderText.title}</p>
          <Link href="/profile">
            <a className={classes.ProfileLink}>{renderText.link}</a>
          </Link>
        </div>
      </header>
      <ChevronRight color="#212121" />
    </div>
  );
};

export default MobileUserControl;

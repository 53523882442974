import React, { FC, useEffect, useState } from 'react';
import { Drawer, Menu } from 'antd';
import Link from 'next/link';
import ds from '@ds';
import { useRouter } from 'next/router';
import {
  Heart,
  Menu as MenuIcon,
  Search,
  ShoppingCart,
  X,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { appActions } from '@actions';
import { Design } from '@components';
import { CloseBanner, getRegion, ShowBanner } from '@helpers';
import { CategoryModel, Region } from '@models';
import { AppBanner, AuthModal, DeliverTo } from '@modules';
import {
  appSelectors,
  cartSelectors,
  categoriesSelectors,
  firebaseSelectors,
  headerLinksSelectors,
  wishlistSelectors,
} from '@selectors';
import { triggerAppBannerClickEvent } from '@utils/seo/events';

import MobileSearchModal from './search-modal/search-modal.module';
import MobileUserControl from './user-controls/user-controls.module';

import styles from './header.module.scss';

const { SubMenu } = Menu;

const MobileHeader: FC = () => {
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [searchModalState, setSearchModalState] = useState<boolean>(false);
  const history = useRouter();
  const dispatch = useDispatch();

  // const { data: wishlistData } = useSelector(wishlistSelectors.selectAll);
  const { data: wishlistCompact } = useSelector(wishlistSelectors.compact);
  const { data: cartData } = useSelector(cartSelectors.compact);
  const { data: menuData } = useSelector(categoriesSelectors.selectAll);
  const { data: headerLinksData } = useSelector(headerLinksSelectors.selectAll);
  const authInfo = useSelector(firebaseSelectors?.auth);
  const visibleAppBanner = useSelector(appSelectors.visibleAppBanner);

  const cartTotalQty =
    cartData?.items?.reduce((prev, current) => prev + current.qty, 0) ?? 0;

  const onDrawerClose = () => {
    setDrawerState(false);
  };

  const onDrawerOpen = (e) => {
    e.preventDefault();
    setDrawerState(true);
  };

  const onOpenSearchModal = () => {
    setSearchModalState(true);
  };

  const onCloseSearchModal = () => {
    setSearchModalState(false);
  };

  const handleInternalClick = (url: string) => {
    setDrawerState(false);
    history.push(url);
  };

  const handleOpenAuthModal = () => dispatch(appActions.toggleAuthModal(true));

  const handleHideAppBanner = (status: boolean) => {
    if (status) triggerAppBannerClickEvent();
    dispatch(appActions.changeAppBannerStatus(false));
    CloseBanner(status);
  };

  useEffect(() => {
    const region = getRegion();

    if (region === Region.AR_QA)
      dispatch(appActions.changeAppBannerStatus(ShowBanner()));
  }, []);

  const renderHeadLinks = () => {
    const headerLinks = headerLinksData?.map((link, idx) => {
      const isExternalLink = link?.referred_url?.includes('http');

      if (isExternalLink) {
        return (
          <Menu.Item key={`${link}_${idx}`} className="menu-item">
            <a href={link.referred_url}>
              <img src={link.icon.urls[12]} alt={link.title} />
              {link.title}
            </a>
          </Menu.Item>
        );
      }

      return (
        <Menu.Item
          className="menu-item"
          key={`${link}_${idx}`}
          onClick={handleInternalClick.bind(null, link.referred_url)}
        >
          <img src={link.icon.urls[12]} alt={link.title} />
          {link.title}
        </Menu.Item>
      );
    });

    return (
      <Menu mode="inline" className="mobile-menu__container" inlineIndent={16}>
        {headerLinks}
        <SubMenu key="FAQ/Support" title="FAQ/Support">
          <Menu.Item
            className="menu-item"
            key="return-policy"
            onClick={handleInternalClick.bind(null, '/return-policy')}
          >
            Refund & Return
          </Menu.Item>
          <Menu.Item
            className="menu-item"
            key="faq"
            onClick={handleInternalClick.bind(null, '/faq')}
          >
            FAQ
          </Menu.Item>
          <Menu.Item
            className="menu-item"
            key="privacy-policy"
            onClick={handleInternalClick.bind(null, '/privacy-policy')}
          >
            Privacy & Policy
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  const renderSubCategories = (rootSlug: string, category: CategoryModel[]) => {
    return category.map((child, index) => (
      <SubMenu
        key={`sub-${rootSlug}-${child.title}-${index}`}
        title={child.title}
      >
        <Menu.Item
          key={`viewall-${rootSlug}-sub-${child.title}-${index}`}
          onClick={handleInternalClick.bind(
            null,
            `/category/${child.slug}/products`
          )}
        >
          <b>View All</b>
        </Menu.Item>
        {child.children.map((item, idx) => {
          return (
            <Menu.Item
              key={`sub-child-${rootSlug}-sub-${child.title}-${item.title}-${idx}`}
              onClick={handleInternalClick.bind(
                null,
                `/category/${item.slug}/products`
              )}
            >
              {item.title}
            </Menu.Item>
          );
        })}
      </SubMenu>
    ));
  };

  const renderCategories = () => {
    return (
      <Menu mode="inline" className="mobile-menu__container" inlineIndent={16}>
        <Menu.ItemGroup key="Categories_List" title="Categories">
          {menuData?.map((root, index) => (
            <SubMenu
              key={`root-${root.title}-${index}`}
              title={root.title}
              icon={
                <img
                  src={
                    root.icon !== null
                      ? root.icon.urls.original
                      : '/assets/icons/shared/HealthHousehold.png'
                  }
                  alt={root.slug}
                />
              }
            >
              <Menu.Item
                key={`viewall-root-${root.title}-${index}`}
                onClick={handleInternalClick.bind(
                  null,
                  `/category/${root.slug}/products`
                )}
              >
                <b>View All</b>
              </Menu.Item>
              {root.children &&
                root.children.length > 0 &&
                renderSubCategories(root.slug, root.children)}
            </SubMenu>
          ))}
        </Menu.ItemGroup>
      </Menu>
    );
  };

  const renderDrawer = () => {
    return (
      <Drawer
        bodyStyle={{
          padding: 0,
        }}
        headerStyle={{ display: 'none' }}
        placement="left"
        onClose={onDrawerClose}
        width={'85vw'}
        visible={drawerState}
        closeIcon={false}
        className={styles.DrawerContainer}
      >
        <>
          <div className={styles.DrawerHeaderContainer}>
            <div
              className={styles.DrawerLogoContainer}
              onClick={handleInternalClick.bind(null, '/')}
            >
              {getRegion() === Region.AR_QA && (
                <h1>Buy Fashion and Beauty Products Online in QATAR</h1>
              )}
              {getRegion() === Region.AR_AE && (
                <h1>Buy Fashion and Beauty Products Online in UAE</h1>
              )}
            </div>
            <X
              onClick={onDrawerClose}
              color={'#757575'}
              width={16}
              height={16}
            />
          </div>

          <MobileUserControl onAuthModal={handleOpenAuthModal} />

          <div className={styles.MenuContainer}>
            <Link
              prefetch={false}
              href={
                authInfo?.isAnonymous || authInfo?.isEmpty
                  ? '/wishlist'
                  : '/profile/wishlist'
              }
            >
              <a className={styles.WishlistMenu}>
                <div>
                  <Heart width={18} height={18} color={ds.color_primary} />
                  Your Wishlist
                </div>
                <div>
                  {wishlistCompact?.products_count &&
                    wishlistCompact?.products_count > 0 && (
                      <div className={`${styles.CountBadge} ${styles.inline}`}>
                        {wishlistCompact?.products_count ?? 0}
                      </div>
                    )}
                </div>
              </a>
            </Link>
          </div>
          <div className={styles.MenuContainer}>{renderHeadLinks()}</div>
          <div className={styles.MenuContainer}>{renderCategories()}</div>
        </>
      </Drawer>
    );
  };

  return (
    <Design className={styles.FixedContainer}>
      {visibleAppBanner && <AppBanner handleClose={handleHideAppBanner} />}

      <div className={styles.Container}>
        <div className={styles.Left}>
          <a href="#" className={styles.MenuIcon} onClick={onDrawerOpen}>
            {wishlistCompact && wishlistCompact.products_count > 0 && (
              <div className={styles.CountBadge}>
                {wishlistCompact.products_count}
              </div>
            )}
            <MenuIcon color="#FFFFFF" width={24} height={24} />
          </a>
          <Design display="flex" alignItems="center">
            <div className={styles.LogoContainer}>
              <Link href="/" prefetch={false}>
                <a>
                  {getRegion() === Region.AR_QA && (
                    <h1>Buy Fashion and Beauty Products Online in QATAR</h1>
                  )}
                  {getRegion() === Region.AR_AE && (
                    <h1>Buy Fashion and Beauty Products Online in UAE</h1>
                  )}
                </a>
              </Link>
            </div>
            {/* <DeliverTo /> */}
          </Design>
        </div>
        <div className={styles.Right}>
          <span className={styles.BasketIcon}>
            <Link href="/cart" prefetch={false}>
              <a>
                {!!cartTotalQty && cartTotalQty > 0 && (
                  <div className={styles.CountBadge}>{cartTotalQty ?? 0}</div>
                )}
                <ShoppingCart color={'#fff'} width={20} height={20} />
              </a>
            </Link>
          </span>

          <a className={styles.SearchIcon} onClick={onOpenSearchModal}>
            <Search width={18} height={18} color={'#fff'} />
          </a>
        </div>

        <AuthModal />

        {renderDrawer()}

        <MobileSearchModal
          onCancel={onCloseSearchModal}
          visibility={searchModalState}
        />
      </div>
    </Design>
  );
};

export default MobileHeader;
